
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IFeedbackUser } from '~/components/sections/clients-feedback/types'

@Component({
  components: {},
})

export default class ClientsFeedbackCard extends Vue {
  @Prop({ required: true, default: () => ({}), type: Object })
  readonly clientInfo!: IFeedbackUser

  @Prop({ default: false, type: Boolean })
  readonly fullWidthText!: boolean

  get getImage () {
    return require(`~/assets/images/clientsFeedback/${this.clientInfo.image}.png`)
  }
}
