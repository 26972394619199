
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ClientsFeedbackCard from '~/components/sections/clients-feedback/ClientsFeedbackCard.vue'
import LoadMoreButton from '~/components/snippets/LoadMoreButton.vue'
import { IFeedbackUser } from '~/components/sections/clients-feedback/types'

@Component({
  components: {
    ClientsFeedbackCard,
    LoadMoreButton,
  },
})
export default class ClientsFeedbackList extends Vue {
  @Prop({ type: Array, required: true })
  readonly feedbacks!: IFeedbackUser[]

  @Prop({ type: Number, default: 3 })
  readonly count!: number

  private currentShowingCard = this.count

  get currentCardList () {
    return this.feedbacks.slice(0, this.currentShowingCard)
  }

  get isListFullShowed () {
    return this.currentCardList.length >= this.feedbacks.length
  }

  loadMoreCards () {
    if (!this.isListFullShowed) {
      this.currentShowingCard += this.count
    }
  }
}
